import { createStore } from 'redux';
import Reducer from '../src/reducer/CounterReducer';

export const web3InitialState = {
    provider: null,
    web3Provider: null,
    address: null,
    network: null,
    connect: null,
    disconnect: null,
    show_mint: false
  }

const initialState = {
    show_mint: false
}
const store = createStore(Reducer, initialState);

export default store;
// CounterAction.js
export const mintEnabled = (enabled) => {
    return {
        type: 'MINT_ENABLED',
        payload: enabled
    }
}

export const setprovider = (provider) => {
    return {
        type: 'PROVIDER',
        payload: provider
    }
}

export const setweb3provider = (provider,web3Provider,address,network) => {
    return {
        type: 'WEB3PROVIDER',
        provider: provider,
        web3Provider: web3Provider,
        address: address,
        network: network,
    }
}

export const resetweb3provider = () => {
    return {
        type: 'RESET_WEB3_PROVIDER',
    }
}

export const reset = () => {
    return {
        type: 'RESET',
    }
}
